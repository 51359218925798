import request from "@utils/request";

//获取用户信息
export const getUserInfo = ()=>{
    return request({
        url:'/index/center/getCenter',
        method:'get',
    })
}
//修改用户名
export const modifyName = (params)=>{
    return request({
        url:'/index/center/editUsername',
        method:'post',
        params,
    })
}
//上传头像
export const uploadAvatar = (data)=>{
    return request({
        url:'index/center/uploadAvatar',
        method:'post',
        data
    })

}
//提交新头像
export const uploadAvatarSet = (params)=>{
    return request({
        url:'index/center/uploadAvatarSet',
        method:'post',
        params
    })
}
//绑定手机
export const  bindPhone =(params)=>{
    return request({
        url:'/index/center/bindPhone',
        method:'post',
        params
    })
}
//实名认证
export  const verified = (params)=>{
    return request({
        url:'index/center/verified',
        method:'post',
        params,
    })
}
//第三方微信绑定
export const wechat = (params) =>{
    return request({
        url:'index/wechat/wechatBindQrCode',
        method:'post',
        params
    })
}
//轮询查询微信绑定结果
export const checkWxBind = (params)=>{
    return request({
        url:'index/wechat/checkWxBind',
        method:'post',
        params
    })
}
//解除微信绑定
export const unbindWechat = (params) =>{
    return request({
        url:'index/center/unbindWechat',
        method:'post',
        params,
    })
}
//设置密码
export const setPassword = (params)=>{
    return request({
        url:'index/center/setPassword',
        method:'post',
        params
    })
}
//实名认证
export const verifiedHelp = ()=>{
    return request({
        url:'/index/center/verifiedHelp',
        method:'get',
    })
}
