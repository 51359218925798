<template>
  <div class='container'>
    <div class="accountSettingBox">
      <h5>账户设置</h5>
      <div class="content">
        <div class="setting-item">
          <span class="setting-item-title">头像：</span>
          <div class="setting-item-content">
            <img class="avatar" :src="userInfo.avatar"/>
            <div class="file-change">
              <span>更换头像</span>
              <input type="file" accept="image/png,image/jpg,image/jpeg" ref="avatar" @change="avatarChange"/>
            </div>
          </div>
        </div>
        <div class="setting-item">
          <span class="setting-item-title">昵称：</span>
          <div class="setting-item-content">
            <div class="nickname" v-if="!nicknameIsChange">
              <span>{{userInfo.username}}</span>
              <a href="javascript:;" @click="nicknameIsChange=true">更改</a>
            </div>
            <div class="nickname-edit" v-else>
              <input type="text" v-model="nickname" placeholder="用户名" autocomplete="off"/>
              <div @click="handelName" class="save">保存</div>
            </div>
          </div>
        </div>
        <div class="setting-item">
          <span class="setting-item-title">手机号：</span>
          <div class="setting-item-content phone">
            <span v-if="isBindPhone">{{userInfo.phone}}</span>
            <i v-else>未绑定手机号</i>
            <a href="javascript:;" @click="bindPhoneVisible=!bindPhoneVisible">
              绑定<img src="@assets/img/profile/icon-arrow_bottom.png"/>
            </a>
          </div>
        </div>
        <div class="setting-phone" v-show="bindPhoneVisible">
          <div class="setting-phone-item" style="margin-bottom:15px">
            <div class="setting-phone-item-title">新手机号</div>
            <div class="setting-phone-item-content">
              <span>+86</span>
              <input type="phone" v-model="newPhone" maxlength="11" style="width: 190px;padding-left:60px" placeholder="新手机号" autocomplete="off"/>
            </div>
          </div>
          <div class="setting-phone-item">
            <div class="setting-phone-item-title">验证码</div>
            <div class="setting-phone-item-content">
              <input maxlength="4" type="text" v-model="code" style="width: 105px;padding-left:10px" placeholder="填写验证码" autocomplete="off"/>
              <i @click="sendCode">{{sendText}}</i>
            </div>
          </div>
          <div  @click="bindNewPhone" class="btn">提交</div>
        </div>
        <div class="setting-item">
          <span class="setting-item-title">实名认证：</span>
          <div class="setting-item-content realName">
            <span v-if="userInfo.verified == 1">已认证</span>
            <span v-else>
              <img src="@assets/img/profile/icon-warning.png"/>未认证
            </span>
            <router-link v-if="userInfo.verified != 1" to="/realNameAuth">
              认证<img src="@assets/img/profile/icon-arrow_right2.png"/>
            </router-link>
          </div>
        </div>
        <div class="setting-item">
          <span class="setting-item-title">第三方账号：</span>
          <div class="setting-item-content third_party"  @click="handelunbindWx" v-if="userInfo.set_wechat_status == 1">
            <img src="@assets/img/index/icon-wx.png"/>
            <router-link to="">
              解除绑定
            </router-link>
          </div>
          <div class="setting-item-content third_party" @click="handelbindWx" v-else>
            <img src="@assets/img/index/icon-wx.png"/>
            <router-link to="">
              微信绑定
            </router-link>
          </div>
        </div>
        <div class="setting-item">
          <span class="setting-item-title">密码：</span>
          <div class="setting-item-content setPwd">
            <span v-if="userInfo.set_password_status == 1">已设置登录密码</span>
            <span v-else>
              <img src="@assets/img/profile/icon-warning.png"/>未设置登录密码
            </span>
            <a href="javascript:;" @click="setPwdVisible=!setPwdVisible;newPwd = '';newPwdConfirm =''">
              更改<img src="@assets/img/profile/icon-arrow_bottom.png"/>
            </a>
          </div>
        </div>
        <div v-show="setPwdVisible">
          <div class="setting-password" v-if="isSetPassword">
            <div class="setting-password-item" style="margin-bottom:15px;">
              <div class="title">新密码</div>
              <input type="password" v-model="newPwd" placeholder="请输入" autocomplete="off"/>
            </div>
            <div class="setting-password-item">
              <div class="title">确认密码</div>
              <input type="password" v-model="newPwdConfirm" placeholder="请输入" autocomplete="off"/>
            </div>
            <div class="btn" @click="submitSetPassword">保存</div>
          </div>
          <div class="setting-password-tip" v-else>
            第三方登录账号无法设置密码，请先绑定手机
          </div>
        </div>
      </div>
      <div v-if="showbindwx" class="wxBox">
        <div class="wxTitle">
          <img src="@assets/img/index/closeQrlogin.png"  @click="closeWx" alt="">
        </div>
        <div class="title">微信绑定</div>
        <div class="contentBox">
          <div class="wxcontent">
            <img :src=wxQr.qr_url alt="">
            <div class="logo">
              <img src="@assets/img/index/icon-wx.png" alt="">
              微信扫一扫
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  bindPhone,
  checkWxBind,
  getUserInfo,
  modifyName,
  setPassword,
  unbindWechat, uploadAvatar, uploadAvatarSet,
  wechat
} from "@assets/api/user/user";
import {getCode} from "@assets/api/home/home";
export default {
  name:'',
  components: {},

  data () {
    return {
      userInfo:{},//用户信息
      reg:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,

      nickname:'eternity',
      nicknameIsChange:false, //昵称修改

      bindPhoneVisible:false, //是否显示绑定手机号
      showbindwx:false,//微信绑定弹窗
      isBindPhone:true, //是否绑定手机号
      sendText:'发送验证码',
      flag:false,
      timer:null,
      newPhone:'',//新手机号
      code:'',//验证码

      isRealName:false, //是否实名
      isBindThird_party:false,  //是否绑定第三方
      isSetPassword:true, //是否设置密码

      setPwdVisible:false, //是否显示设置密码
      newPwd:'',
      newPwdConfirm:'',
      wxQr:{},//微信绑定二维码
      closeSetInterval:'',//微信轮询定时器
    };
  },

  computed: {},

  created(){
    this.getUser()
    this.getWxQr()
    clearInterval(this.closeSetInterval)
  },
  beforeCreate() {
    clearInterval(this.closeSetInterval)
  },
  methods: {
    //获取用户信息
    getUser(){
      getUserInfo().then(res=>{
        if(res.code == 1){
          this.userInfo = res.data
          this.nickname = this.userInfo.username
          this.$store.commit('getUserInfo',this.userInfo)
          console.log('data',this.userInfo)
        }
      })
    },
    getWxQr(){
      wechat().then(res=>{
        console.log('res',res)
        if(res.code == 1){
          this.wxQr = res.data
        }
      })
    },
    //微信绑定
    handelbindWx(){
      this.showbindwx = true
      this.closeSetInterval =  setInterval(()=>{
       let params = {
         log_id:this.wxQr.log_id
       }

       checkWxBind(params).then(res=>{
         console.log('res',res)
         if(res.code == 1){
           this.$Message.success(`${res.msg}`)
           this.showbindwx = false
           this.userInfo.set_wechat_status = 1
           clearInterval(this.closeSetInterval)
         }
       })
      },2000)

    },
    //解除微信绑定
    handelunbindWx(){
      unbindWechat().then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          if(this.userInfo.phone != ''){
            this.userInfo.set_wechat_status = 0
            this.getWxQr()
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }


      })
    },
    closeWx(){
      this.showbindwx = false;
      clearInterval(this.closeSetInterval)
    },
    //上传头像
    avatarChange(e){
			console.log(e.target.files);
			if(e.target.files.length==0){
				return false;
			}
      let _this=this;
			let file=e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
        return this.$Message.warning('请上传正确的文件格式');
      }

      this.$refs.avatar.value=null;
      let formData = new FormData
      formData.append('file',file)
      uploadAvatar(formData).then(res=>{
        console.log('res',res)
        if(res.code == 1){
          this.userInfo.avatar = res.data.img
          this.submitAvatar(res.data)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //保存头像
    submitAvatar(data){
      let params = {
        img_sub:data.img_sub
      }
      uploadAvatarSet(params).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //发送验证码
    sendCode(){
      if(this.flag){
        return false;
      }
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if(this.newPhone == ''){
        return this.$Message.error('手机号不能为空')
      }else if(this.newPhone.length <= 10 ||!reg.test(this.newPhone )) {
        return this.$Message.error('请填写正确的手机号')
      }

      let params = {
        phone:this.newPhone
      }
      getCode(params).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          // this.code = res.msg.substr(res.msg.length - 4)
        }
      })

      this.flag=true;
      let time=10;
      this.timer=setInterval(()=>{

        time--;
        if(time==0){
          clearInterval(this.timer);
          this.sendText='发送验证码';
          time=10;
          this.flag=false;

        }else{
          this.sendText=time+'s'
        }
      },1000)
    },
    //修改用户名
    handelName(){
      let params = {
        username:this.nickname
      }
      modifyName(params).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.nicknameIsChange = false
          this.getUser()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //绑定手机
    bindNewPhone(){
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if(this.newPhone == ''){
        return this.$Message.error('手机号不能为空')
      }else if(this.newPhone.length <= 10 ||!reg.test(this.newPhone )) {
        return this.$Message.error('请填写正确的手机号')
      }
      if(this.code == ''){
        return this.$Message.error('验证码不能为空')
      }else if(this.code.length>4){
        return this.$Message.error('请填写正确的验证码')
      }
      let params ={
        phone:this.newPhone,
        msg_code:this.code,
      }

      bindPhone(params).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.userInfo.phone = this.newPhone
          setTimeout(()=>{
            this.bindPhoneVisible  = false
          },2000)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //设置密码
    submitSetPassword(){
      if(this.newPwd == ''){
        return this.$Message.error('新密码不能为空')
      }else if(this.newPwdConfirm == ''){
        return this.$Message.error('确认密码不能为空')
      }else if(this.newPwd != this.newPwdConfirm){
        return this.$Message.error('密码不一致')
      }
      let params = {
        password:this.newPwd,
        re_password:this.newPwdConfirm
      }
      setPassword(params).then(res=>{
        if(res.code == 1){
          console.log('res',res)

          this.getUser()
          this.setPwdVisible = false
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/accountSetting.less';
</style>