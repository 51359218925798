import request from "@/utils/request";
import {exp} from "qrcode/lib/core/galois-field";

//获取网站基本信息
export const getWebInfo = (data)=>{
    return  request({
        url:'/index/index/getWebInfo',
        method:'get',
    })
}
//获取首页基本参数
export const getHomeInfo = ()=>{
    return request({
        url:'/index/index/getIndexSet',
        method:'get'
    })
}
//获取上传又拍云前的参数
export const uploadBefore = ()=>{
    return request({
        url:'/index/upload/uploadBefore',
        method:'get'
    })
}
//获取验证码
export const getCode = (params) =>{
    return request({
        url:'/index/login/sendMsg',
        method:'post',
        params,
    })
}
//手机号登录
export const phoneLogin = (params)=>{
    return request({
        url:'/index/login/registerMobile',
        method:'post',
        params
    })
}
//密码登录
export const pwdLogin = (params) =>{
    return request({
        url:'/index/login/pwdLogin',
        method:'post',
        params
    })
}
//获取微信二维码
export const getWxCode = (params)=>{
    return request({
        url:'/index/wechat/wechatLoginQrCode',
        method:'post',
        params
    })
}
//二维码登录
export const WxCodeLogin = (params)=>{
    return request({
        url:'/index/wechat/checkWxLogin',
        method:'post',
        params
    })
}
//忘记密码
export const findPwd = (params)=>{
    return request({
        url:'/index/login/findPwd',
        method:'post',
        params
    })
}
//发文件
export  const  uploadFile =  (data)=> {
    return request({
        url:'/index/upload/uploadFile',
        method:'post',
        data,
    })
}

//上传云盘文件
export  const uploadFileByPan = (data) =>{
    return request({
        url:'/index/member/uploadFileByPan',
        method:'post',
        data
    })
}

//生成分享链接的二维码图片
export const createShareQr = (params)=>{
    return request({
        url:'/index/upload/createShareQr',
        method:'post',
        params
    })
}
export const downloadQrImage = (params)=>{
    return request({
        url:'index/upload/downloadQrImage',
        method:'post',
        params
    })
}
// http://fzdnz.craftsman-web.com.cn//?share_id=511
//发送分享链接至邮件
export const sendOutEmail = ( params) =>{
    return request({
        url:'/index/upload/sendShareEmail',
        method:'post',
        params
    })
}

//取件
export const  getFileInfo = ( params) =>{
    return request({
        url:'/index/upload/pickup',
        method:'post',
        params
    })
}
//取件支付
export const sendBuyShare = (params) =>{
    return request({
        url:'index/shop/sendBuyShare',
        method:'post',
        params
    })
}
//校验订单是否支付
export const  checkLinkOrderPay = (params)=>{
    return request({
        url:'index/shop/checkLinkOrderPay',
        method:'post',
        params
    })
}


//获取文件目录
export const getFileFolder = (params) =>{
    return request({
        url:'/index/upload/getShareFileList',
        method:'post',
        params
    })
}
//获取举报标签
export const getReportTags = () =>{
    return request({
        url:'/index/upload/getReportTags',
        method:'get',
    })
}
//提交举报
export const SubmitReport = (data)=>{
    return request({
        url:'index/member/sendReport',
        method:'post',
        data
    })
}
//文件预览
export const previewFile = (params)=>{
    return request({
        url:'/index/upload/previewFile',
        method:'post',
        params
    })
}
//文件下载
export const fileDownload = (data) => {
    return request({
        url:'/index/upload/shareDownload',
        method:'post',
        data:data
    })
}
//查询文件下载
export const inquireTask = (params)=>{
    return request({
        url:'/index/upload/inquireTask',
        method:'post',
        params
    })
}
//发表评论
export const sendComment = (params)=>{
    return request({
        url:'/index/upload/sendComment',
        method:'post',
        params
    })
}
//评论点赞
export const clickComment = (params)=>{
    return request({
        url:'/index/upload/likesComment',
        method:'post',
        params,
    })
}
//全盘搜索
export const search =(params)=>{
    return request({
        url:'/index/index/searchAll',
        method:'post',
        params,
    })
}

//获取云盘数据
export const getPanList =(params)=>{
    return request({
        url:'/index/member/getPanList',
        method:'post',
        params
    })
}
//获取选择的文件大小
export const getSizeTotal = (data)=>{
    return request({
        url:'index/upload/getSizeTotal',
        method:'post',
        data
    })
}
//转存
export const saveToDisk =(data)=>{
    return request({
        url:'index/member/saveToDisk',
        method:'post',
        data
    })
}
